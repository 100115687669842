import create from 'zustand'

const useStore = create(set => ({
    gender: '',
    bodyType: 3,
    heightFt: '',
    heightIn: '',
    heightCm: '',
    weightLbs: '',
    weightKg: '',
    targetWeightLbs: '',
    targetWeightKg: '',
    useLbs: true,
    age: ''
}));

export default useStore;
