import Step from "../../components/step/Step";
import {FormattedMessage, useIntl} from "react-intl";
import useStore from "../../storage/DataStore";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";
import {Field, Form, Formik} from "formik";
import {
    Box, Button,
    FormControl, FormErrorMessage,
    NumberInput, NumberInputField, NumberInputStepper,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs, Text
} from "@chakra-ui/react";

function Step13() {
    const intl = useIntl()
    let navigate = useNavigate();

    const [unit, setCurrentUnit] = useState(useStore().useLbs ? 'lbs' : 'kg');

    const validationSchemas = {
        lbs: Yup.object().shape({
            weightLbs: Yup.number().required(intl.formatMessage({id: 'form.weightLbs.isRequired'}))
        }),
        kg: Yup.object().shape({
            weightKg: Yup.number().required(intl.formatMessage({id: 'form.weightKg.isRequired'}))
        })
    }

    const [currentValidationSchema, setCurrentValidationSchema] = useState(unit === 'lbs' ? validationSchemas.lbs : validationSchemas.kg);

    const handleTabChange = (index) => {
        if (index === 0) {
            setCurrentUnit('lbs');
            setCurrentValidationSchema(validationSchemas.lbs);
        } else {
            setCurrentUnit('kg');
            setCurrentValidationSchema(validationSchemas.kg);
        }
    }

    const getTabIndex = () => {
        return unit === 'lbs' ? 0 : 1;
    }

    const submit = (values) => {
        if (unit === 'kg') {
            useStore.setState({
                weightKg: values.weightKg,
                weightLbs: '',
                useLbs: false
            });
        } else {
            useStore.setState({
                weightLbs: values.weightLbs,
                weightKg: '',
                useLbs: true
            });
        }

        navigate('/step14');
    }

    return (
        <Step heading={intl.formatMessage({id: 'step13.headline'})}
              currentStep={13}
              backLink={'/step12'}>
            <Formik
                initialValues={{
                    weightKg: useStore().weightKg,
                    weightLbs: useStore().weightLbs
                }}
                validationSchema={currentValidationSchema}
                onSubmit={submit}
            >
                <Form>
                    <Tabs textAlign={'left'}
                          align={'center'}
                          variant='soft-rounded'
                          colorScheme="red"
                          onChange={handleTabChange}
                          index={getTabIndex()}>
                        <TabList>
                            <Tab background={'#f6f7f9'}>lbs</Tab>
                            <Tab background={'#f6f7f9'}>kg</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel pb={0}>
                                <Field name={'weightLbs'}>
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.weightLbs && form.touched.weightLbs}>
                                            <NumberInput {...field}
                                                         onChange={(val) =>
                                                             form.setFieldValue(field.name, val)
                                                         }
                                                         id="weightLbs"
                                                         min={55}
                                                         max={662}
                                                         precision={0}
                                                         step={1}>
                                                <NumberInputField/>
                                                <NumberInputStepper width={50} alignItems={'end'} pr={2}
                                                                    justifyContent={'center'}>
                                                    <Text fontSize={'1.2rem'} fontWeight={'bold'}>lbs</Text>
                                                </NumberInputStepper>
                                            </NumberInput>

                                            <FormErrorMessage>{form.errors.weightLbs}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Box textAlign={'center'} pt={4} fontSize={['sm', 'md']}>
                                    Please, enter a value from <strong>55 lbs</strong> to <strong>662 lbs</strong>
                                </Box>
                            </TabPanel>
                            <TabPanel pb={0}>
                                <Field name={'weightKg'}>
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.weightKg && form.touched.weightKg}>
                                            <NumberInput {...field}
                                                         onChange={(val) =>
                                                             form.setFieldValue(field.name, val)
                                                         }
                                                         id="weightKg"
                                                         min={25}
                                                         max={300}
                                                         precision={0}
                                                         step={1}>
                                                <NumberInputField/>
                                                <NumberInputStepper width={50} alignItems={'end'} pr={2}
                                                                    justifyContent={'center'}>
                                                    <Text fontSize={'1.2rem'} fontWeight={'bold'}>kg</Text>
                                                </NumberInputStepper>
                                            </NumberInput>

                                            <FormErrorMessage>{form.errors.weightKg}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Box textAlign={'center'} pt={4} fontSize={['sm', 'md']}>
                                    Please, enter a value from <strong>25 kg</strong> to <strong>300 kg</strong>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Button bg="brand"
                            color={'white'}
                            size="md"
                            mt={5} w={'100%'}
                            type="submit">
                        <FormattedMessage id="form.button.next"/>
                    </Button>
                </Form>
            </Formik>
        </Step>
    )
}

export default Step13;