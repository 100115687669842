import "./Duo.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'
import {Box, Text} from "@chakra-ui/react";
import Countdown from "react-countdown";
import moment from "moment/moment";

function DuoTemplate(props) {
    const currentDate = moment().format('dddd, MMMM Do YYYY');

    const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            return <></>;
        }

        return <>HURRY {minutes}:{seconds} !</>
    }

    const isMayaMethod = window.location.href.includes('mymthd');

    return (
        <div className={"page duo-gumm"}>
            { isMayaMethod === false &&
                <Box background={'black'} color={'white'} fontSize={['sm', 'md']} p={[2,3]} textAlign={'center'}>
                    <strong>Warning:</strong> Due to extremely high media demand, there is limited supply of Duo Keto
                    Gummies in stock as of <Text display={'inline'} color={'yellow'}>{currentDate}! <Countdown
                    renderer={countdownRenderer} date={Date.now() + (5 * 60 * 1000)}/></Text>
                </Box>
            }
            { isMayaMethod &&
                <Box background={'black'} color={'white'} fontSize={['sm', 'md']} p={[2,3]} textAlign={'center'}>
                    <strong>Attention:</strong> Due to extremely high demand for Maya's Method, there is a
                    limited supply of Duo Keto Gummies in stock as of <Text display={'inline'} color={'yellow'}>{currentDate}!</Text>
                </Box>
            }

            {props.children}
        </div>
    );
}

export default DuoTemplate;
