import Step from "../../components/step/Step";
import {Field, Form, Formik} from 'formik';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    InputGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage, useIntl} from "react-intl";
import {useState} from "react";

function Step12() {
    const intl = useIntl()
    let navigate = useNavigate();

    const [unit, setCurrentUnit] = useState(useStore().heightCm === '' ? 'ft' : 'cm');

    const validationSchemas = {
        ft: Yup.object().shape({
            heightFt: Yup.number().required(intl.formatMessage({id: 'form.heightFt.isRequired'})),
            heightIn: Yup.number().required(intl.formatMessage({id: 'form.heightIn.isRequired'}))
        }),
        cm: Yup.object().shape({
            heightCm: Yup.number().required(intl.formatMessage({id: 'form.heightCm.isRequired'}))
        })
    }

    const [currentValidationSchema, setCurrentValidationSchema] = useState(unit === 'ft' ? validationSchemas.ft : validationSchemas.cm);

    const handleTabChange = (index) => {
        if (index === 0) {
            setCurrentUnit('ft');
            setCurrentValidationSchema(validationSchemas.ft);
        } else {
            setCurrentUnit('cm');
            setCurrentValidationSchema(validationSchemas.cm);
        }
    }

    const getTabIndex = () => {
        return unit === 'ft' ? 0 : 1;
    }

    const submit = (values) => {
        if (unit === 'ft') {
            useStore.setState({
                heightFt: values.heightFt,
                heightIn: values.heightIn,
                heightCm: ''
            });
        } else {
            useStore.setState({
                heightFt: '',
                heightIn: '',
                heightCm: values.heightCm
            });
        }

        navigate('/step13');
    }

    return (
        <Step heading={intl.formatMessage({id: 'step12.headline'})}
              currentStep={12}
              backLink={'/step11'}>
            <Formik
                initialValues={{
                    heightFt: useStore().heightFt,
                    heightIn: useStore().heightIn,
                    heightCm: useStore().heightCm
                }}
                validationSchema={currentValidationSchema}
                onSubmit={submit}
            >
                <Form>
                    <Tabs textAlign={'left'}
                          align={'center'}
                          variant='soft-rounded'
                          colorScheme="red"
                          onChange={handleTabChange}
                          index={getTabIndex()}>
                        <TabList>
                            <Tab background={'#f6f7f9'}>ft</Tab>
                            <Tab background={'#f6f7f9'}>cm</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel pb={0}>
                                <SimpleGrid gap={3} columns={2}>
                                    <Box>
                                        <Field name={'heightFt'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.heightFt && form.touched.heightFt}>
                                                    <InputGroup>
                                                        <NumberInput {...field}
                                                                     onChange={(val) =>
                                                                         form.setFieldValue(field.name, val)
                                                                     }
                                                                     id="heightFt"
                                                                     min={3}
                                                                     max={7}
                                                                     precision={0}
                                                                     defaultValue={'Height'}
                                                                     step={1}>
                                                            <NumberInputField/>
                                                            <NumberInputStepper width={50} alignItems={'end'} pr={2}
                                                                                justifyContent={'center'}>
                                                                <Text fontSize={'1.2rem'} fontWeight={'bold'}>ft</Text>
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.heightFt}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                    <Box>
                                        <Field name={'heightIn'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.heightIn && form.touched.heightIn}>
                                                    <InputGroup>
                                                        <NumberInput {...field}
                                                                     onChange={(val) =>
                                                                         form.setFieldValue(field.name, val)
                                                                     }
                                                                     id="heightIn"
                                                                     min={1}
                                                                     max={11}
                                                                     precision={0}
                                                                     step={1}>
                                                            <NumberInputField/>
                                                            <NumberInputStepper width={50} alignItems={'end'} pr={2}
                                                                                justifyContent={'center'}>
                                                                <Text fontSize={'1.2rem'} fontWeight={'bold'}>in</Text>
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.heightIn}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                </SimpleGrid>

                                <Box textAlign={'center'} pt={4} fontSize={['sm', 'md']}>
                                    Please, enter a value from <strong>3 ft</strong> to <strong>7 ft 11 in</strong>
                                </Box>
                            </TabPanel>
                            <TabPanel pb={0}>
                                <Field name={'heightCm'}>
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.heightCm && form.touched.heightCm}>
                                            <InputGroup>
                                                <NumberInput {...field}
                                                             onChange={(val) =>
                                                                 form.setFieldValue(field.name, val)
                                                             }
                                                             id="heightCm"
                                                             min={90}
                                                             max={243}
                                                             width={'100%'}
                                                             precision={0}
                                                             defaultValue={'Height'}
                                                             step={1}>
                                                    <NumberInputField/>
                                                    <NumberInputStepper width={50} alignItems={'end'} pr={2}
                                                                        justifyContent={'center'}>
                                                        <Text fontSize={'1.2rem'} fontWeight={'bold'}>cm</Text>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </InputGroup>

                                            <FormErrorMessage>{form.errors.heightCm}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Box textAlign={'center'} pt={4} fontSize={['sm', 'md']}>
                                    Please, enter a value from <strong>90 cm</strong> to <strong>243 cm</strong>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Button bg="brand"
                            color={'white'}
                            size="md"
                            mt={5} w={'100%'}
                            type="submit">
                        <FormattedMessage id="form.button.next"/>
                    </Button>
                </Form>
            </Formik>
        </Step>
    )
}

export default Step12;
