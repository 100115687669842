import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import {IntlProvider} from "react-intl";
import English from './lang/en.json';

import ChakraTheme from "./templates/Chakra";
import DuoTemplate from "./templates/Duo";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Step9 from "./pages/step9/Step9";
import Step10 from "./pages/step10/Step10";
import Step11 from "./pages/step11/Step11";
import Step12 from "./pages/step12/Step12";
import Step13 from "./pages/step13/Step13";
import Step14 from "./pages/step14/Step14";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

import success1 from "./assets/step4/success4.jpg";
import success2 from "./assets/step4/success1.jpg";
import success3 from "./assets/step9/success1.jpg";
import useImagePreloader from "./hooks/UseImagePreloader";

function App() {
    const preloadSrcList = [
        success1,
        success2,
        success3
    ]

    useImagePreloader(preloadSrcList)

    let messages = English;
    let locale = 'en';
    moment.locale('en');

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('abversion') && urlParams.get('abversion') === 'b') {
        messages.outgoingUrl = 'https://duo-gummies.com/en/order-now-v2.html';
    }
    if (urlParams.has('abversion') && urlParams.get('abversion') === 'a') {
        messages.outgoingUrl = 'https://duo-gummies.com/en/order-now-v3.html';
    }

    document.title = messages.title + ' - Duo Gummies';

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <DuoTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/step7' element={
                                <Step7/>
                            }/>
                            <Route path='/step8' element={
                                <Step8/>
                            }/>
                            <Route path='/step9' element={
                                <Step9/>
                            }/>
                            <Route path='/step10' element={
                                <Step10/>
                            }/>
                            <Route path='/step11' element={
                                <Step11/>
                            }/>
                            <Route path='/step12' element={
                                <Step12/>
                            }/>
                            <Route path='/step13' element={
                                <Step13/>
                            }/>
                            <Route path='/step14' element={
                                <Step14/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete/>
                            }/>
                        </Routes>
                    </DuoTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
