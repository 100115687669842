import './Products.scss';
import package1 from '../../assets/products/package-1.png';
import package2 from '../../assets/products/package-2.png';
import package3 from '../../assets/products/package-3.png';
import package4 from '../../assets/products/package-4.png';
import {Box, Heading, Text} from "@chakra-ui/react";
import Packages from '../../lang/en/products.json';
import LowPricePackages from '../../lang/en/products-lp.json';
import LowPricePackages2 from '../../lang/en/products-lp2.json';
import {FormattedMessage} from "react-intl";

function Products(props) {
    const images = {
        'package1': package1,
        'package2': package2,
        'package3': package3,
        'package4': package4
    };

    const urlParams = new URLSearchParams(window.location.search);
    let packages = Packages;

    if (urlParams.has('abversion')) {
        const abVersion = urlParams.get('abversion');
        if (abVersion === 'b') {
            packages = LowPricePackages;
        } else if (abVersion === 'a') {
            packages = LowPricePackages2;
        }
    }

    const whichPackage = () => {
        const abVersion = urlParams.get('abversion');
        if (abVersion === 'a') {
            switch (true) {
                case props.totalDays >= 1 && props.totalDays <= 15:
                    return 'package1';
                case props.totalDays >= 15 && props.totalDays <= 25:
                    return 'package2';
                case props.totalDays >= 25 && props.totalDays <= 35:
                    return 'package3';
                case props.totalDays >= 35:
                    return 'package4';
                default:
                    return 'package4';
            }
        } else {
            // Original package calculation for other versions
            switch (true) {
                case props.totalDays >= 1 && props.totalDays <= 15:
                    return 'package1';
                case props.totalDays >= 15 && props.totalDays <= 25:
                    return 'package2';
                case props.totalDays > 35:
                    return 'package3';
                default:
                    return 'package3';
            }
        }
    };

    const getImage = (product) => {
        return images[product];
    };

    let product = packages[whichPackage()];

    return (
        <Box className="packages-container" textAlign={'center'}>
            <Heading as="h3" size="xl">{product.heading}</Heading>

            <img className={'product-image'} src={getImage(whichPackage())} alt=""/>

            <Heading as="h4" size="md" mb={3}>{product.subheading}</Heading>

            <Text>{product.description}</Text>

            <Box my={6}>
                <Heading as="h3" size="xl">{product.price}</Heading>
                <Text fontSize="14">
                    <FormattedMessage id="product.bottlePrice"/>
                </Text>
            </Box>

            <Text>{product.totalPrice}</Text>
        </Box>
    );
}

export default Products;
